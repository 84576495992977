.logo-header{
  min-width: 7%;
  width: 7%;
}

.Sidebar-rocket-logo{
  width:15%;
}

.text-header{
  color: white;
  font-size: 1rem;
}

.bordered{
  border-radius:5px
}

.main-container{
  padding-left: 20px;
}

.GVIS-Background, .bg-dark {
  background-color: #232f3e !important;
}

.footer-text{
  color: white;
  font-size: 0.5rem;
}

.pro-sidebar-inner, .pro-inner-list-item{
  background: #1b2848 !important;
}

.pro-icon-wrapper{
  background: #0d68b1 !important;
}

.btn-toggle{
  position: absolute;
  top: 83px;
  left: 30px;
  z-index: 1;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #1b2848;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.logo-login{
  width: 70%;
}

.logo-container{
  text-align: right;
}

.login-footer{
  background-color: rgb(37, 47, 61);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
}

.menu-label{
  margin-bottom: 0rem;
  cursor: pointer;
}

.footer-login-text{
  color: #fff !important;
}

.capitalize{
  text-transform: capitalize;
}

.to-lower-case{
  text-transform: lowercase;
}

.curso_anuncio_container{
  width: 100%;
  height: 150px;
  overflow: auto;
}

.text-editor{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.editor-border{
  border: 1px solid #ced4da
}

.editor-anuncios-curso{
  height: 109px !important;
}

.editor-anuncios-institucion{
  height: 209px !important;
}

.action-button{
  padding: 0 !important;
}

.hide-element{
  opacity: 0;
}

.hide-element-container:hover .hide-element{
  opacity: 1;
}

.hide-element-container:hover {
  box-shadow:inset 0 0 1px;
}

.styless-button{
  border:0px;
}

.styless-button:focus{
  outline: none;
  text-decoration: underline;
}

.styless-button:active{
  border:2px;
  border-color: red;
}

.react-form-builder-toolbar {
  margin-top: 0!important;
  padding-top: 1rem !important;
}

.styless-button:visited{
  border:2px;
  border-color: red;
}

.fixed-top{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 53px;
  background-color: white;
}

@media (max-width: 768px) {
  .btn-toggle{
    display: flex;
  }
  .logo-login{
    width: 30%;    
  }
  .logo-container{
    text-align: center;
  }
}

